
<template>
<div class="row justify-content-center" style="height: 100vh;">
    <div class="col-md-8 col-lg-6 col-xl-5 d-flex align-items-center justify-content-center flex-column mx-auto">
        <div class="card">
            <div class="card-body p-4">

                <div class="text-center w-75 m-auto">
                    <div class="auth-logo">
                        <router-link to="/" class="logo logo-dark text-center">
                            <span class="logo-lg">
                                <img src="/images/logo-dark.png" alt="" height="22">
                            </span>
                        </router-link>

                        <router-link to="/" class="logo logo-light text-center">
                            <span class="logo-lg">
                                <img src="/images/logo-light.png" alt="" height="22">
                            </span>
                        </router-link>
                    </div>
                </div>

                <div class="text-center mt-4">
                    <h1 class="text-error">Error</h1>
                    <h3 class="mt-3 mb-2">You are not Authorized</h3>
                    <p class="text-muted mb-3">Why not try refreshing your page? or you can contact <a href="" class="text-dark"><b>Support</b></a></p>
                    <b-button @click="redirect" class="btn btn-primary mt-3"><i class="mdi mdi-reply mr-1"></i> Return Home</b-button>
                </div>

            </div> 
        </div>

    </div>
</div>
</template>

<script>

export default {
  methods: {
    redirect(){
      location.replace(window.location.origin+'/')
    },
   },

  }
</script>